// the bootstrap module doesn't export/return anything
require('bootstrap');
require('block-ui');
var bsCustomFileInput = require('bs-custom-file-input');


// Fontawseome
require('@fortawesome/fontawesome-pro/css/all.min.css');
require('@fortawesome/fontawesome-pro/js/all.js');

// Fancybox
//require('@fancyapps/fancybox');

// Bootstrap Select
require('bootstrap-select/js/bootstrap-select.js');
require('bootstrap-select/sass/bootstrap-select.scss');

require('../css/footer.scss');

$(document).ready(function () {
	/* Bug bei custom upload bootstrap */
	bsCustomFileInput.init();

	// *** TODO: Localize Select picker
	if(window.location.href.indexOf('/de/') > 0) {
		require('bootstrap-select/js/i18n/defaults-de_DE.js');
	}
});

var $th = $('.tableFixHead').find('thead th');
$('.tableFixHead').off();
$('.tableFixHead').scroll(function() {
  $th.css('transform', 'translateY('+ this.scrollTop +'px)');
});
